import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExperienceViewOnePage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperienceViewOnePage'
    )
);

const ConnectedExperiencePurchaseConfirmationPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperiencePurchaseConfirmationPage'
    )
);

const ConnectedExperiencePaymentAcceptedPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperiencePaymentAcceptedPage'
    )
);

const ConnectedExperiencePaymentConflictPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperiencePaymentConflictPage'
    )
);

const ConnectedExperienceCreationChatPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceCreation/ConnectedExperienceCreationChatPage'
    )
);

const ConnecteBenefitsRequirementsPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceCreation/ConnectedBenefitsRequirmentsPage'
    )
);

const ConnectedMyExperiencesPage = lazy(
  () =>
    import(
      '../components/smart/pages/experienceCreation/ConnectedMyExperiencesPage'
    )
);

const ConnectedExperienceActionsPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperienceActionsPage'
    )
);

const ConnectedExperienceDeletePage = lazy(
  () =>
    import('../components/smart/pages/experience/ConnectedExperienceDeletePage')
);

const ConnectedExperienceCancelPage = lazy(
  () =>
    import('../components/smart/pages/experience/ConnectedExperienceCancelPage')
);

const ConnectedExperienceEditPage = lazy(
  () =>
    import('../components/smart/pages/experience/ConnectedExperienceEditPage')
);

const ConnectedExperienceEditBenefitPage = lazy(
  () => import('../components/smart/pages/experience/ConnectedEditBenefitsPage')
);

const ConnectedExperienceEditRequirementPage = lazy(
  () =>
    import('../components/smart/pages/experience/ConnectedEditRequirementsPage')
);

const ConnectedExperienceInviteGuestPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedExperienceInviteGuestPage'
    )
);

const ConnectedStartConversationPage = lazy(
  () =>
    import(
      '../components/smart/pages/experience/ConnectedStartConversationPage'
    )
);

const EXPERIENCE_VIEW_ONE: Route = {
  component: ConnectedExperienceViewOnePage,
  path: PATH.EXPERIENCE.VIEW_ONE,
  isProtected: false,
};

const EXPERIENCE_VIEW_ONE_BY_SLUG: Route = {
  component: ConnectedExperienceViewOnePage,
  path: PATH.EXPERIENCE.VIEW_ONE_BY_SLUG,
  isProtected: false,
};

const EXPERIENCE_PURCHASE_CONFIRMATION: Route = {
  component: ConnectedExperiencePurchaseConfirmationPage,
  path: PATH.EXPERIENCE.PURCHASE_CONFIRMATION,
  isProtected: true,
};

const EXPERIENCE_PAYMENT_ACCEPTED: Route = {
  component: ConnectedExperiencePaymentAcceptedPage,
  path: PATH.EXPERIENCE.PAYMENT_ACCEPTED,
  isProtected: true,
};

const EXPERIENCE_PAYMENT_CONFLICT: Route = {
  component: ConnectedExperiencePaymentConflictPage,
  path: PATH.EXPERIENCE.PAYMENT_CONFLICT,
  isProtected: true,
};

const EXPERIENCE_START_CONVERSATION: Route = {
  component: ConnectedStartConversationPage,
  path: PATH.EXPERIENCE.START_CONVERSATION,
  isProtected: true,
};

const EXPERIENCE_CREATE: Route = {
  component: ConnectedExperienceCreationChatPage,
  path: PATH.EXPERIENCE.CREATE,
  isProtected: true,
};

const EXPERIENCE_BENEFITS_REQUIREMENTS: Route = {
  component: ConnecteBenefitsRequirementsPage,
  path: PATH.EXPERIENCE.BENEFITS_REQUIREMENTS,
  isProtected: true,
};

const EXPERIENCE_ADD: Route = {
  component: ConnectedMyExperiencesPage,
  path: PATH.EXMINDER.MY_EXPERIENCES,
  isProtected: true,
  hasMenuBar: true,
};

const EXPERIENCE_ACTIONS: Route = {
  component: ConnectedExperienceActionsPage,
  path: PATH.EXPERIENCE.ACTIONS,
  isProtected: true,
};

const EXPERIENCE_ACTIONS_DELETE: Route = {
  component: ConnectedExperienceDeletePage,
  path: PATH.EXPERIENCE.DELETE,
  isProtected: true,
};

const EXPERIENCE_ACTIONS_CANCEL: Route = {
  component: ConnectedExperienceCancelPage,
  path: PATH.EXPERIENCE.CANCEL,
  isProtected: true,
};

const EXPERIENCE_ACTIONS_EDIT: Route = {
  component: ConnectedExperienceEditPage,
  path: PATH.EXPERIENCE.UPDATE,
  isProtected: true,
};

const EXPERIENCE_EDIT_BENEFITS: Route = {
  component: ConnectedExperienceEditBenefitPage,
  path: PATH.EXPERIENCE.EDIT_BENEFITS,
  isProtected: true,
};

const EXPERIENCE_EDIT_REQUIREMENTS: Route = {
  component: ConnectedExperienceEditRequirementPage,
  path: PATH.EXPERIENCE.EDIT_REQUIREMENTS,
  isProtected: true,
};

const EXPERIENCE_INVITE_GUEST: Route = {
  component: ConnectedExperienceInviteGuestPage,
  path: PATH.EXPERIENCE.INVITE_GUEST,
  isProtected: false,
};

export default [
  EXPERIENCE_VIEW_ONE,
  EXPERIENCE_PURCHASE_CONFIRMATION,
  EXPERIENCE_PAYMENT_ACCEPTED,
  EXPERIENCE_PAYMENT_CONFLICT,
  EXPERIENCE_START_CONVERSATION,
  EXPERIENCE_CREATE,
  EXPERIENCE_BENEFITS_REQUIREMENTS,
  EXPERIENCE_ADD,
  EXPERIENCE_ACTIONS,
  EXPERIENCE_ACTIONS_DELETE,
  EXPERIENCE_ACTIONS_CANCEL,
  EXPERIENCE_ACTIONS_EDIT,
  EXPERIENCE_EDIT_BENEFITS,
  EXPERIENCE_EDIT_REQUIREMENTS,
  EXPERIENCE_INVITE_GUEST,
  EXPERIENCE_VIEW_ONE_BY_SLUG,
];

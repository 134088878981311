import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const RoleIconContainer = styled.div<{
  animate: boolean;
  image: string;
}>`
  width: 225px;
  height: 225px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1),
    transform 1s cubic-bezier(0.42, 0, 0.58, 1);
  transform: ${({ animate }) => (animate ? 'scale(5)' : 'scale(1)')};
  opacity: ${({ animate }) => (animate ? '0' : '1')};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export const CompleteProfileContent = styled.div`
  width: 100%;
  max-width: 275px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  @media only screen and (max-width: 338px) {
    align-items: center;
  }
`;

export const MissingInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const MissingInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(4)};
  color: ${({ theme }) => theme.palette.main.black};
  line-height: ${({ theme }) => theme.typography.pxToRem(8)};
  text-align: center;
`;

export const RegularText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  text-align: center;
`;

export const LightText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.darkGray};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  text-align: left;
`;

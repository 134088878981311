import Button from 'components/dumb/atomics/Button';
import theme from 'styles/theme/classic';
import { t } from 'i18next';
import * as SC from './styles';
import { backgroundContainerClass } from '../../../../helpers/constants/styling';

const ErrorBoundaryIcon = '/error.png';

interface Props {
  icon?: string;
  title?: string;
  subtitle?: string;
  action?: string;
  onResetErrorBoundary: CallbackFunction;
}

export default function ErrorBoundaryCustom({
  icon = ErrorBoundaryIcon,
  title = t(`errorBoundary.title`),
  subtitle = t(`errorBoundary.subtitle`),
  action = t(`errorBoundary.recoveryButton`),
  onResetErrorBoundary,
}: Props) {
  return (
    <SC.Container>
      <SC.Background>
        <SC.EllipseContainer>
          <SC.ClosedEllipseRotated />
          <SC.OpenEllipse />
          <SC.OpenEllipseSmall />
          <SC.ClosedEllipse />
        </SC.EllipseContainer>
        <SC.Wrapper className={backgroundContainerClass}>
          <SC.MainContent>
            <SC.IconContainer icon={icon} />
            <SC.BoldText>{title}</SC.BoldText>
            <SC.RegularText>{subtitle}</SC.RegularText>
          </SC.MainContent>
          <SC.ButtonContainer>
            <Button
              color={theme.palette.button.main.yellow}
              onClick={onResetErrorBoundary}
            >
              {action}
            </Button>
          </SC.ButtonContainer>
        </SC.Wrapper>
      </SC.Background>
    </SC.Container>
  );
}

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import file from '../../assets/videos/portrait.mp4';

async function measureInternetSpeed(thresholdMbps: number): Promise<{
  speedMbps: number | null;
  isSpeedAboveThreshold: boolean;
}> {
  try {
    const startTime = performance.now();
    const response = await fetch(file, { cache: 'no-cache' });
    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000;

    if (response.ok) {
      const contentLength = response.headers.get('content-length');
      const fileSizeInBytes = contentLength ? parseInt(contentLength, 10) : 500;

      const bitsLoaded = fileSizeInBytes * 8;
      const speedBps = bitsLoaded / duration;
      const speedKbps = speedBps / 1024;
      const speedMbps = speedKbps / 1024;

      return {
        speedMbps,
        isSpeedAboveThreshold: speedMbps >= thresholdMbps,
      };
    }
    return {
      speedMbps: null,
      isSpeedAboveThreshold: false,
    };
  } catch (error) {
    return {
      speedMbps: null,
      isSpeedAboveThreshold: false,
    };
  }
}

export const useCheckInternetSpeed = (thresholdMbps: number) => {
  const { t } = useTranslation();
  const [downloadSpeedMbps, setDownloadSpeedMbps] = useState<number | null>(
    null
  );
  const [isSpeedAcceptable, setIsSpeedAcceptable] = useState(false);
  const [isSpeedLoading, setIsSpeedLoading] = useState(true);

  useEffect(() => {
    setIsSpeedLoading(true);
    measureInternetSpeed(thresholdMbps)
      .then(({ speedMbps, isSpeedAboveThreshold }) => {
        setDownloadSpeedMbps(speedMbps);
        setIsSpeedAcceptable(isSpeedAboveThreshold);
      })
      .catch((error) => {
        toast.error(t(`toast.error`) as string, {
          toastId: t(`toast.error`),
        });
        console.error(error);
      })
      .finally(() => {
        setIsSpeedLoading(false);
      });
  }, [thresholdMbps, t]);

  return { speedMbps: downloadSpeedMbps, isSpeedAcceptable, isSpeedLoading };
};

import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExperiencePinCodePage = lazy(
  () =>
    import(
      '../components/smart/pages/experiencePinCode/ConnectedExperiencePinCodePage'
    )
);

const ConnectedExperiencePinCodeValidatedPage = lazy(
  () =>
    import(
      '../components/smart/pages/experiencePinCode/ConnectedExperiencePinCodeValidatedPage'
    )
);

const EXPERIENCE_PIN_CODE: Route = {
  component: ConnectedExperiencePinCodePage,
  path: PATH.EXPERIENCE_PIN_CODE.PIN,
  isProtected: true,
};

const EXPERIENCE_PIN_CODE_VALIDATED: Route = {
  component: ConnectedExperiencePinCodeValidatedPage,
  path: PATH.EXPERIENCE_PIN_CODE.VALIDATED,
  isProtected: true,
};

export default [EXPERIENCE_PIN_CODE, EXPERIENCE_PIN_CODE_VALIDATED];

import { useTranslation } from 'react-i18next';
import ExplorerIcon from 'assets/images/onboarding/explorer.webp';
import * as SC from './styles';
import { SideBarSteps } from '../../../../../../helpers/constants/enums';
import AddNewIcon from '../../../../../../assets/icons/SideBarMenuIcons/AddNewIcon';

export type ExplorerRequiredInformation = {
  hasBiography: boolean;
  hasEducation: boolean;
  hasJobRole: boolean;
};

interface Props {
  currentStep: number;
  shouldAnimate: boolean;
  explorerRequiredInformation: ExplorerRequiredInformation;
}

export default function ExplorerCompleteProfileStep({
  currentStep,
  shouldAnimate,
  explorerRequiredInformation,
}: Props) {
  const { t } = useTranslation();
  if (currentStep !== SideBarSteps.CompleteProfileStep) {
    return null;
  }

  return (
    <SC.Container>
      <SC.RoleIconContainer animate={shouldAnimate} image={ExplorerIcon} />
      <SC.MainContent>
        <SC.BoldText>
          {t(`sidebarMenu.completeProfile.explorer.youAreAnExplorer`)}
        </SC.BoldText>
        <SC.RegularText>
          {t(`sidebarMenu.completeProfile.fillIn`)}
        </SC.RegularText>
        <SC.CompleteProfileContent>
          <SC.RegularText>
            {t(`sidebarMenu.completeProfile.explorer.complete`)}
          </SC.RegularText>
          <SC.MissingInformationContent>
            {!explorerRequiredInformation.hasBiography && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.explorer.biography`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!explorerRequiredInformation.hasEducation && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.explorer.education`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
            {!explorerRequiredInformation.hasJobRole && (
              <SC.MissingInformationRow>
                <SC.IconContainer>
                  <AddNewIcon />
                </SC.IconContainer>
                <SC.LightText>
                  {t(`sidebarMenu.completeProfile.explorer.jobRole`)}
                </SC.LightText>
              </SC.MissingInformationRow>
            )}
          </SC.MissingInformationContent>
        </SC.CompleteProfileContent>
        <SC.RegularText>
          {t(`sidebarMenu.completeProfile.explorer.otherInformation`)}
        </SC.RegularText>
      </SC.MainContent>
    </SC.Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  min-width: 320px;
  background: ${({ theme }) => theme.palette.main.white};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`;

export const IconContainer = styled.div<{
  icon: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 95px;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-repeat: no-repeat;

  animation: pulse 3s ease-in-out infinite;
  animation-fill-mode: forwards;
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

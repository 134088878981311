import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  min-width: 320px;
  background: ${({ theme }) => theme.palette.main.white};
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.main.cyan};
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  padding: 40px 20px;
  overflow: auto;
`;

export const MainContent = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
  flex-grow: 1;
`;

export const IconContainer = styled.div<{
  icon: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 107px;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.main.white};
  text-align: center;
  white-space: pre-wrap;
`;

export const RegularText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(0)};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  color: ${({ theme }) => theme.palette.main.white};
  text-align: center;
  white-space: pre-wrap;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EllipseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const ClosedEllipseRotated = styled.div`
  width: 318px;
  height: 318px;
  border-radius: 50%;
  background: radial-gradient(
    63.52% 63.52% at 77.99% 14.78%,
    #ffc537 0%,
    rgba(0, 175, 185, 0) 100%
  );
  margin: 150px 0 -50px 500px;
  transform: rotate(-129.1deg);
`;

export const OpenEllipse = styled.div`
  width: 293px;
  height: 293px;
  background: radial-gradient(
    36.98% 36.98% at 50% 50%,
    #ffc537 0%,
    rgba(255, 204, 77, 0) 100%
  );
  margin: -50px 400px 0 0;
`;

export const OpenEllipseSmall = styled.div`
  width: 228px;
  height: 228px;
  background: radial-gradient(
    36.98% 36.98% at 50% 50%,
    #ffc537 0%,
    rgba(255, 204, 77, 0) 100%
  );
  margin: 75px 0 -75px 325px;
`;

export const ClosedEllipse = styled.div`
  width: 318px;
  height: 318px;
  border-radius: 50%;
  background: radial-gradient(
    63.52% 63.52% at 77.99% 14.78%,
    #ffc537 0%,
    rgba(0, 175, 185, 0) 100%
  );
  margin: 0 400px 100px 0;
`;
